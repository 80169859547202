import Vue from 'vue'
import { validationMixin } from 'vuelidate';

export default {

	mixins: [validationMixin],

	data: function() {

		return {
			is: {
				loaded: false,
				saving: false,
				deleting: false,
				error: false
			},
			original: {},
			enableStash: false,
			validations: {}
		}

	},

	computed: {

		stashName: function() {

			return this.store + '/' + this.model.id

		},

		title: function() {

			return (this.isNew) ? 'New ' + this.noun : 'Edit ' + this.noun

		},

		isNew: function() {

			return this.$route.params[this.param] === 'new' && !this.model.id

		},

		isDirty: function() {

			return !this.$_.isEqual(this.model, this.original)

		}

	},

	beforeRouteEnter: function(to, from, next) {

		if (from.meta.stash === to.name) {

			next(vm => {

				vm.enableStash = true

				if (vm.$store.getters['stash'][vm.stashName] && vm.enableStash) {

					Vue.set(vm, 'model', vm.$util.clone(vm.$store.getters['stash'][vm.stashName]))
		
				}

				vm.runCallbacks()

			})

		} else {

			next(vm => {

				vm.runCallbacks()

			})

		}

	},

	beforeRouteLeave: function(to, from, next) {

		if (to.meta.stash === from.name || this.isDirty) {

			this.$store.commit('stash', {
				name: this.stashName,
				data: this.$util.clone(this.model)
			})

		} 

		if (this.isDirty && !to.meta.stash) {
			
			next({
				name: from.name + '.Unsaved',
				query: {
					redirect: to.path
				}
			})

		} else [

			next()

		]

	},

	created: function() {

		if (this.default) this.default()

		if (!this.isNew && this.store && !this.isStandalone) {

			Vue.set(this, 'model', this.$util.clone(this.$store.getters[this.store + '/find'](this.$route.params[this.param])))

		}

		this.updateOriginal()

		this.is.loaded = true

	},

	methods: {

		runCallbacks: function() {

			this.$_.each(this.callbacks, function(callback) {

				if (this.$route.params[callback]) this[callback](this.$route.params[callback])

			}.bind(this))

		},

		updateOriginal: function() {

			Vue.set(this, 'original', this.$util.clone(this.model))

		},

		clearStash: function() {

			this.$store.commit('stash', {
				name: this.stashName,
				data: false
			})

		},

		onUndoClick: function() {

			Vue.set(this, 'model', this.$util.clone(this.original))

			this.clearStash()

		},

		onSaveClick: async function() {

			this.$v.$touch()

			if (this.$v.$invalid) {

				this.is.error = true

			} else {
				
				this.is.error = false

				this.clearStash()

				this.is.saving = true

				var data = this.$util.clone(this.model)

				if (this.extra) data.extra = this.extra
	
				await this.$store.dispatch(this.store + '/save', data)

				this.original = this.$util.clone(this.model)

				if (this.goBackAfterSave !== undefined) {
					
					this.goBackAfterSave()

				} else {

					this.goBack()

				}

			}
			
		},

		onDeleteClick: async function() {

			this.clearStash()

			this.is.deleting = true

			await this.$store.dispatch(this.store + '/delete', this.model)

			this.goBack()

		},

		goBack: function(meta) {

			this.clearStash()

			this.$router.push({
				name: this.$route.meta.back || this.$route.meta.parent,
				params: meta || false
			})

		}

	}

}