<template>

<app-page>

	<app-page-head :title="title" back="Locations">

		<app-page-head-action :disabled="!isDirty" icon="undo" tooltip="Undo changes" v-on:click.native="onUndoClick" />
		<app-page-head-action :loading="is.saving" :disabled="!isDirty" icon="save" tooltip="Save changes" v-on:click.native="onSaveClick" />
		<app-page-head-action route="Location.Delete" v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" tooltip="Delete" />
		
	</app-page-head>

	<app-page-content :is-grown="true">

		<app-page-content-section :is-padded="true" is-divided="!isNew" :grow="true">

			<app-input-text v-model="model.title" :validation="$v.model.title" label="Title" placeholder="Enter title" :maxlength="64" />
			<app-input-toggle v-model="model.flag" :validation="$v.model.flag" label="Flagged" />
			<app-input-slug v-model="model.slug" :validation="$v.model.slug" :source="model.title" label="Slug" placeholder="Enter slug" :maxlength="64" />
			<app-input-image v-model="model.logo" :validation="$v.model.logo" label="Logo/Icon" />
			<app-input-text v-model="model.map" :validation="$v.model.map" label="Map embed" placeholder="Enter embed code" :autogrow="true" />
			<app-input-toggle v-model="model.services" label="Services" :stacked="true" :options="$constants.locationServices" :multiple="true" />
			<app-input-tags v-model="model.terms" :dynamic-tag="model.title" label="Terms" />
			<app-input-texts v-model="model.texts" label="Content blocks" store="locations" route="Location.Text" />

		</app-page-content-section>

		<app-page-content-link v-if="!isNew" is-divided="true" icon="groups" title="Groups" route="Location.Groups" :params="{location: model.id}" :count="useCount" />

	</app-page-content>

	<app-page-foot />

</app-page>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			model: {
				id: 0,
				slug: '',
				logo: '',
				flag: 0,
				title: '',
				map: '',
				terms: [],
				texts: [],
				services: []
			},
			param: 'location',
			noun: 'Location',
			store: 'locations',
			callbacks: [
				'textRemove',
				'textDelete',
				'textInsert',
				'textReplace'
			]
		}

	},

	validations: {
		model: {
			title: {
				required,
				maxLength: maxLength(64)
			},
			slug: {
				required
			},
			logo: {
				required
			},
			map: {
				
			},
			services: {
				minLength: minLength(1)
			}
		}
	},

	computed: {

		useCount: function() {

			var uses = 0

			this.$_.each(this.$store.getters['groups'], function(group) {

				this.$_.each(group.texts, function(id) {

					var text = this.$_.findWhere(this.$store.getters['texts'], {
						id: id
					})

					if (text) {

						if (text.location === this.model.id) uses++
					
					}
				
				}.bind(this))

			}.bind(this))

			return uses

		},

		texts: function() {

			return this.$store.getters['texts/find/index'](this.model.texts)

		},

		watchTexts: function() {

			return this.model.texts

		}

	},

	methods: {

		textRemove: function(id) {

			this.model.texts.splice(this.model.texts.indexOf(id), 1)

		},

		textInsert: function(e) {

			this.model.texts.splice(e.position, 0, e.id)

		},

		textReplace: function(e) {

			this.model.texts[this.model.texts.indexOf(e.from)] = e.to

		},

		textDelete: function(id) {

			this.model.texts.splice(this.model.texts.indexOf(id), 1)
			if (this.original.texts.indexOf(id) !== -1) this.original.texts.splice(this.original.texts.indexOf(id), 1)

		}

	}

}

</script>

<style scoped>

</style>
